<template>
  <div class="page" id="reorganize">
    <el-row :gutter="10">
      <el-col :span="showList ? 4 : 1" :class="['p_r', showList ? '' : 'el-col-unfold']">
        <div :class="['unfoldClassifyBtn', showList ? 'el-icon-arrow-left':'el-icon-arrow-right']"
             @click="showList = !showList"></div>
        <div class="leftHeighe bg-white" v-show="showList">
          <el-descriptions title="藏品分类"></el-descriptions>
          <div class="flex_b_c">
            <el-input size="small" placeholder="输入关键字进行过滤" clearable v-model="filterText"
                      @input="filterTreeData"></el-input>
          </div>
          <el-tree v-loading="treeLoading" node-key="id" ref="tree" :data="treeData" class="treeDateCls"
                   show-checkbox :filter-node-method="filterNode"
                   :props="defaultProps" @check="selRecord">
            <div slot-scope="{ node, data }">
              <el-tooltip class="item" effect="dark" :content="node.label" placement="top-start">
                <div class="omit">{{ node.label }}</div>
              </el-tooltip>
            </div>
          </el-tree>
        </div>
      </el-col>
      <el-col :span="showList ? 20 : 24" :class="[showList ? '' : 'el-col-unfold','leftHeighe']">
        <div class="text_center noData" v-if="!archivesBasicDataId.length">请选择左侧藏品分类</div>
        <div v-else>
          <div class="bg-white">
            <el-form size="small"
                     @keyup.enter.native="getDataList(1,1,1)"
                     class="query-form dynamicForm" ref="inputForm" :model="inputForm"
                     :rules="rules"
                     label-width="150px"
            >
              <el-form-item label="藏品名称" prop="collectionName">
                <el-input v-model.trim="inputForm.collectionName"
                          placeholder="请输入藏品名称(限50字)"
                          maxlength="50" clearable></el-input>
              </el-form-item>
              <el-form-item label="藏品编号" prop="helpNum">
                <el-input v-model.trim="inputForm.helpNum"
                          placeholder="请输入藏品编号(限50字)"
                          maxlength="50"
                          clearable>
                </el-input>
              </el-form-item>
              <el-form-item label="完残程度" prop="integrity">
                <el-select v-model="inputForm.integrity"
                           placeholder="请选择完残程度"
                           style="width: 100%"
                           clearable>
                  <el-option
                      v-for="item in integrityList"
                      :key="item.id"
                      :label="item.levelName"
                      :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="完残情况" prop="integrityInfo">
                <el-input v-model="inputForm.integrityInfo"
                          placeholder="请输入完残情况(限50字)"
                          maxlength="50" clearable></el-input>
              </el-form-item>
              <el-form-item label-width="0" :prop="item.code"
                            v-for="(item, index) in config"
                            :key="index" v-if="item.dataWhere == 0">
                <div class="flex_b_c metadata">
                  <el-tooltip :disabled="item.overflow" class="item" effect="dark" :content="item.basicName"
                              placement="top">
                    <div class="omit text_right metadataName" ref="configName">{{ item.basicName }}</div>
                  </el-tooltip>
                  <div class="metadataCont">
                    <!--字符-->
                    <el-input v-model.trim="inputForm[item.code]" :maxlength="item.maxLength"
                              :placeholder="item.forName ? '请输入' + item.forName : '请输入' + item.basicName"
                              clearable
                              v-if="item.archivesBasicTypeCode == 'text' && !item.archivesBasicMultiselectList.length"></el-input>
                    <!--数值、浮点数-->
                    <el-input v-model.trim="inputForm[item.code]"
                              :maxlength="item.archivesBasicTypeCode == 'integer'? '8':'11'"
                              @input="getNumType(item)"
                              :placeholder="item.forName ? '请输入' + item.forName : '请输入' + item.basicName"
                              clearable
                              v-if="item.archivesBasicTypeCode == 'integer' || item.archivesBasicTypeCode == 'double'"></el-input>
                    <el-select class="w100i" v-model="inputForm[item.code]"
                               :multiple="item.archivesBasicMultiselectList[0].selectBy == 1" collapse-tags
                               :placeholder="item.forName ? '请选择' + item.forName : '请选择' + item.basicName"
                               clearable
                               v-if="item.archivesBasicMultiselectList.length">
                      <el-option v-for="(v,i) in item.archivesBasicMultiselectList" :key="i"
                                 :label="v.archivesBasicValue"
                                 :value="v.archivesBasicValue"></el-option>
                    </el-select>
                    <!--时间-->
                    <el-date-picker class="w100i"
                                    v-model="inputForm[item.code]" value-format="yyyy-MM-dd HH:mm:ss"
                                    format="yyyy-MM-dd HH:mm:ss"
                                    type="datetime" clearable placeholder="请选择"
                                    v-if="item.archivesBasicTypeCode == 'date' && item.dataFormat == 'yyyy-MM-dd HH:mm:ss'">
                    </el-date-picker>
                    <el-date-picker class="w100i"
                                    v-model="inputForm[item.code]" value-format="yyyy-MM-dd HH:mm:ss"
                                    format="yyyy-MM-dd"
                                    type="date" clearable placeholder="请选择"
                                    v-if="item.archivesBasicTypeCode == 'date' && item.dataFormat == 'yyyy-MM-dd' || item.archivesBasicTypeCode == 'date' && !item.dataFormat">
                    </el-date-picker>
                    <el-date-picker class="w100i"
                                    v-model="inputForm[item.code]" value-format="yyyy-MM-dd HH:mm:ss"
                                    format="yyyyMMdd"
                                    type="date" clearable placeholder="请选择"
                                    v-if="item.archivesBasicTypeCode == 'date' && item.dataFormat == 'yyyyMMdd'">
                    </el-date-picker>
                  </div>
                </div>
              </el-form-item>
            </el-form>
            <div class="flex_b_c">
              <div>
                <el-button size="small" type="primary" icon="el-icon-search" @click="getDataList(1,1,1)">
                  查询
                </el-button>
                <el-button size="small" @click="resetting()" icon="el-icon-refresh-right">重置</el-button>
              </div>
              <!--                        <el-button size="small" @click="advancedSearch()">高级检索</el-button>-->
              <div>
                <el-button type="primary"
                           size="small" icon="el-icon-circle-check"
                           v-show="hasPermissionButton(`collection:business:${typePage}:chThrough`)"
                           @click="through(1,'',3)">批量通过
                </el-button>
                <el-button type="danger"
                           size="small" icon="el-icon-circle-close"
                           v-show="hasPermissionButton(`collection:business:${typePage}:batchReject`)"
                           @click="through(1,'',2)">批量驳回
                </el-button>
              </div>
            </div>
          </div>

          <div class="bg-white" style="margin-top: 10px">
            <div class="text_right">
              <el-button
                  size="small"
                  v-show="hasPermissionButton(`collection:business:${typePage}:batchExpor`)"
                  @click="batchExpor()">
                <i class="icon-piliangdaochu iconfont buIcon"/>
                批量导出
              </el-button>
            </div>
            <el-table
                :data="dataList"
                size="small"
                :row-key="'id'"
                @selection-change="selectionChangeHandle"
                v-loading="loading"
                @sort-change="sortChange"
                ref="multipleTable"
                height="calc(100vh - 350px)"
                class="table"
            >
              <el-table-column :reserve-selection="true" type="selection" width="50"/>
              <el-table-column label="序号" type="index" width="50"></el-table-column>
              <el-table-column prop="logoffState" label="审核状态"
                               show-overflow-tooltip>
                <template slot-scope="scope">
                  {{ $dictUtils.getDictLabel("process_state", scope.row.logoffState, '-') }}
                </template>
              </el-table-column>
              <el-table-column prop="name" label="图标" width="120px">
                <template slot-scope="scope">
                  <div class="flex_a_c">
                    <div v-if="scope.row.carded == 0"
                         class="icon-wenwukapian iconfont listIcon"></div>
                    <el-image
                        style="width: 36px; height: 36px"
                        :src="scope.row.picMasterViewUrl"
                        :fit="'cover'"
                        :preview-src-list="[scope.row.picMasterViewUrl]"
                    >
                      <div slot="error" class="image-slot">
                        <el-image
                            style="width: 36px; height: 36px"
                            :src="require('@/assets/img/default.png')"
                            :fit="'cover'">
                        </el-image>
                      </div>
                    </el-image>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="collectionName" label="藏品名称"
                               show-overflow-tooltip></el-table-column>
              <el-table-column prop="helpNum" label="辅助账编号" show-overflow-tooltip></el-table-column>
              <el-table-column prop="generalNum" label="总账编号" show-overflow-tooltip></el-table-column>
              <el-table-column prop="integrityInfo" label="完残情况"
                               show-overflow-tooltip></el-table-column>
              <el-table-column prop="integrity" label="完残程度" show-overflow-tooltip></el-table-column>
              <el-table-column prop="cStoreState" label="库存状态" show-overflow-tooltip>
                <template slot-scope="scope">
                  {{ $dictUtils.getDictLabel("cStore_state", scope.row.cStoreState, '-') }}
                </template>
              </el-table-column>
              <el-table-column v-for="(item, index) in config" :key="item.archivesBasicId"
                               :prop="item.code"
                               :sortable="item.archivesBasicTypeCode == 'date' || item.archivesBasicTypeCode == 'integer' ? 'custom' : false"
                               :label="item.basicName" v-if="item.dataSelect == 0" min-width="120"
                               show-overflow-tooltip>
                <template slot="header" slot-scope="scope">
                  <el-tooltip class="item" effect="dark" :content="item.forName ? item.forName : item.basicName"
                              placement="top-start">
                    <span class="omit">{{ item.forName ? item.forName : item.basicName }}</span>
                  </el-tooltip>
                </template>
                <template slot-scope="scope">
                  <span
                      v-if="item.archivesBasicTypeCode == 'date'">{{ metadataDateFormatValue(item, scope.row.archivesData[item.code]) }}</span>
                  <span v-else>{{ scope.row.archivesData[item.code] }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="createUserName" label="创建者"
                               show-overflow-tooltip></el-table-column>
              <el-table-column prop="createDate" sortable label="创建时间" width="150px">
                <template slot-scope="scope">
                  {{ scope.row.createDate | formatDate }}
                </template>
              </el-table-column>
              <el-table-column prop="updateUserName" label="最后更新人"
                               show-overflow-tooltip></el-table-column>
              <el-table-column prop="updateDate" sortable label="最后更新时间" width="150px"
                               show-overflow-tooltip>
                <template slot-scope="scope">
                  {{ scope.row.updateDate | formatDate }}
                </template>
              </el-table-column>
              <el-table-column fixed="right" label="操作" width="180">
                <template slot-scope="scope">
                  <el-button size="mini" type="text"
                             @click="detail(3,scope.row,scope.$index)"
                             v-show="hasPermissionButton(`collection:business:${typePage}:detail`)">详情
                  </el-button>
                  <el-button size="mini" type="text"
                             @click="record(scope.row)"
                             v-show="hasPermissionButton(`collection:business:${typePage}:record`)">
                    审核记录
                  </el-button>
                  <el-button size="mini" type="text"
                             @click="through(2,scope.row,3)"
                             v-show="hasPermissionButton(`collection:business:${typePage}:through`)">
                    通过
                  </el-button>
                  <el-button size="mini" type="text"
                             @click="through(2,scope.row,2)"
                             v-show="hasPermissionButton(`collection:business:${typePage}:reject`)">驳回
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
            <div class="text_center">
              <el-pagination
                  @size-change="sizeChangeHandle"
                  @current-change="currentChangeHandle"
                  :current-page="pageNo"
                  :page-sizes="[10, 20, 50, 100]"
                  :page-size="pageSize"
                  :total="total"
                  background
                  layout="total, sizes, prev, pager, next, jumper"
              >
              </el-pagination>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
    <record ref="record"></record>
    <reviewSuggestions ref="suggestions" @updataStatus="getDataList(1,1);getAllDataLength()"></reviewSuggestions>
    <ledge-result ref="ledgeResult"></ledge-result>
    <ExportData ref="exportData" @downLoad="getDataList('',1);getAllDataLength()"></ExportData>
    <AdvancedSearch ref="advancedSearch" @getDataList="getSrhCondition"></AdvancedSearch>
  </div>
</template>

<script>
import {hasPermissionButton} from "@/utils";
import record from "@/views/modules/collection/accounts/module/record.vue";
import reviewSuggestions from "@/views/modules/collection/accounts/module/reviewSuggestions.vue";
import LedgeResult from "@/views/modules/collection/accounts/module/ledgeResult.vue";
import ExportData from "@/views/modules/record/collect/exportData.vue";
import AdvancedSearch from "@/components/advancedSearch/advancedSearch.vue";


export default {
  name: "cancellationReview",
  components: {AdvancedSearch, LedgeResult, reviewSuggestions, record, ExportData},
  props: {
    typePage: {
      type: String,
      default: 'cancellationReview',
    },
    fieldList: {
      type: Array,
      default: () => [
        {
          "archivesBasicTypeMethodCode": "=",
          "key": "entryState",
          "keyName": "",
          "theKey": "",
          "value": 1
        },
        {
          archivesBasicTypeMethodCode: "=",
          key: "logoffState",
          theKey: "",
          value: "1",
        },
      ],
    }
  },
  data() {
    return {
      treeData: [],
      treeLoading: false,
      filterText: '',
      defaultProps: {
        children: 'children',
        label: 'archivesBasicName'
      },
      archivesBasicDataId: [],
      retract: true,
      showList:true,

      config: [],
      inputForm: {
        helpNum: '',
        collectionName: '',
      },
      rules: {},

      loading: false,
      dataList: [],
      dataListSelect: [],
      pageNo: 1,
      pageSize: 10,
      total: 0,
      allData: 0,
      advancedList: [],
      searchForm: {
        whereList: [],
        sortArchivesDataElasticsearch: [],
      },
      searchRecord: {},
      integrityList: [
        {
          id: '残缺',
          levelName: '残缺',
        },
        {
          id: '基本完整',
          levelName: '基本完整',
        },
        {
          id: '完整',
          levelName: '完整',
        },
        {
          id: '严重残缺',
          levelName: '严重残缺',
        },
      ],
    }
  },

  mounted() {
    let listSearch = JSON.parse(sessionStorage.getItem('listSearch'))
    if (listSearch) {
      this.archivesBasicDataId = listSearch.archivesBasicDataId
      this.advancedList = listSearch.advancedList
      this.inputForm = listSearch.inputForm
      this.searchForm.whereList = listSearch.advancedList
      this.pageNo = listSearch.pageNo
      this.pageSize = listSearch.pageSize
      this.searchTree(1)
      sessionStorage.removeItem('listSearch')
    } else {
      this.searchTree()
    }

    if (this.typePage === 'auxiliaryAccount') {
      this.getAllDataLength(2)
    }
    if (this.typePage === 'ledger') {
      this.getAllDataLength(1)
    }
  },

  methods: {
    hasPermissionButton,

    // 查询树状数据
    searchTree(type) {
      this.treeData = [
        {
          id: '',
          archivesBasicName: '全部',
          children: [],
        },
      ]
      this.treeLoading = true
      this.$axios(this.api.collection.selectArchivesBasicData, {}, 'post').then((res) => {
        if (res.status) {
          this.treeData[0].children = res.data
          //全选
          this.$nextTick(() => {
            if (!type) {
              this.getTreeId(this.treeData)
            }
            this.$refs.tree.setCheckedKeys(this.archivesBasicDataId);
            this.selRecord('', '', type)
          })
        }
        this.treeLoading = false
      })
    },

    //循环取树形控件id
    getTreeId(data) {
      if (data && data.length != 0) {
        data.forEach(item => {
          this.archivesBasicDataId.push(item.id)
          if (item.children) {
            this.getTreeId(item.children)
          }
        })
      } else {
        return
      }
    },

    // 选择档案分类
    selRecord(data, checked, type) {
      ////该节点所对应的对象、树目前的选中状态对象
      this.searchForm.sortArchivesDataElasticsearch = []
      if (checked) {
        this.archivesBasicDataId = []
        this.archivesBasicDataId = checked.checkedNodes.map(item => {
          return item.id
        })
      }
      let getByIdUrl = this.api.collection.basicdataGetById + '/' + this.archivesBasicDataId
      if (this.archivesBasicDataId.length > 1) {
        getByIdUrl = this.api.collection.getArchivesBasicByRequired
      }
      this.$axios(getByIdUrl).then(data => {
        if (data.status) {
          if (!type) {
            this.inputForm = {
              helpNum: '',
              collectionName: '',
              integrity: '',
              integrityInfo: '',
            }
          }
          this.rules = {}
          this.config = data.data.archivesBasicDataGroup
          this.config.map(item => {
            if (item.dataWhere == 0) {
              this.$set(item, 'value', '')
              this.$set(this.inputForm, item.code, '')
              if (item.archivesBasicTypeCode == 'integer') {
                this.$set(this.rules, item.code, [{
                  validator: this.validator.isDigits,
                  trigger: 'blur'
                }])
              }
            }
          })
          this.$nextTick(() => {
            this.textExceeds(this.config, 'configName')
          })
          this.getDataList(type ? '' : 1, 1, 1)
        }
      })
    },

    //判断文字是否超出范围
    textExceeds(list, refName) {
      let configName = this.$refs[refName]
      if (!list || !list.length) return
      list.forEach((item, index) => {
        if (configName[index].scrollWidth > configName[index].clientWidth) {
          this.$set(item, 'overflow', false)
        } else {
          this.$set(item, 'overflow', true)
        }
      })
    },

    // 高级检索
    advancedSearch() {
      this.$refs.advancedSearch.init(this.config, this.advancedList)
    },

    // 获取高级检索条件
    getSrhCondition(data) {
      if (data) {
        this.advancedList = data.advancedList
        this.searchForm.whereList = data.whereList
        this.getDataList(1, 1, 1)
      }
    },

    //获取列表数据
    getDataList(type, dividePage, archivesData) {
      this.$refs['inputForm'].validate((valid) => {
        if (valid) {
          if (type == 1) {
            this.pageNo = 1
          }
          if (dividePage) {
            this.$refs.multipleTable.clearSelection()
          }
          if (archivesData == 1) {
            this.searchForm.sortArchivesDataElasticsearch = []
            this.$refs.multipleTable.clearSort()
          }
          this.loading = true
          let keys = Object.keys(this.inputForm)
          let values = Object.values(this.inputForm)
          let searchForm = JSON.parse(JSON.stringify(this.searchForm))
          keys.map((key, index) => {
            this.config.map(item => {
              if (item.code == key && values[index] != '' && values[index] != null) {
                if (item.archivesBasicTypeCode == 'text') {
                  // 多选遍历多选的值
                  if (Array.isArray(values[index])) {
                    values[index].map(v => {
                      searchForm.whereList.push({
                        archivesBasicTypeMethodCode: "%",
                        key: item.code,
                        value: v,
                        archivesBasicTypeCode: item.archivesBasicTypeCode,
                      })
                    })
                  } else {
                    if (item.archivesBasicMultiselectList.length) {
                      searchForm.whereList.push({
                        archivesBasicTypeMethodCode: "=",
                        archivesBasicTypeCode: item.archivesBasicTypeCode,
                        key: item.code,
                        value: values[index]
                      })
                    } else {
                      searchForm.whereList.push({
                        archivesBasicTypeMethodCode: "%",
                        archivesBasicTypeCode: item.archivesBasicTypeCode,
                        key: item.code,
                        value: values[index]
                      })
                    }
                  }
                }
                if (item.archivesBasicTypeCode == 'integer') {
                  searchForm.whereList.push({
                    archivesBasicTypeMethodCode: "=",
                    key: item.code,
                    value: values[index],
                    archivesBasicTypeCode: item.archivesBasicTypeCode,
                  })
                }
                if (item.archivesBasicTypeCode == 'date') {
                  searchForm.whereList.push({
                    archivesBasicTypeMethodCode: "<=",
                    key: item.code,
                    value: values[index],
                    archivesBasicTypeCode: item.archivesBasicTypeCode,
                  })
                  searchForm.whereList.push({
                    archivesBasicTypeMethodCode: ">=",
                    key: item.code,
                    value: values[index],
                    archivesBasicTypeCode: item.archivesBasicTypeCode,
                  })
                }
                if (item.archivesBasicTypeCode == "double") {
                  searchForm.whereList.push({
                    archivesBasicTypeMethodCode: "=",
                    archivesBasicTypeCode: item.archivesBasicTypeCode,
                    key: item.code,
                    value: values[index]
                  })
                }
              }
            })
          })
          let archivesBasicDataId = this.archivesBasicDataId.filter(item => {
            return item == ''
          })
          if (archivesBasicDataId.length != 0) {
            archivesBasicDataId = null
          } else {
            archivesBasicDataId = this.archivesBasicDataId
          }
          let fieldList = JSON.parse(JSON.stringify(this.fieldList))
          let fieldOrList = []
          if (this.inputForm.collectionName) {
            fieldList.push(
                {
                  archivesBasicTypeMethodCode: "%",
                  archivesBasicTypeCode: 'text',
                  key: 'collectionName',
                  value: this.inputForm.collectionName
                },
            )
          }
          let fieldWhereList = []
          if (this.inputForm.integrity) {
            fieldWhereList.push(
                {
                  archivesBasicTypeMethodCode: "=",
                  archivesBasicTypeCode: 'text',
                  key: 'integrity',
                  value: this.inputForm.integrity
                },
            )
          }
          if (this.inputForm.integrityInfo) {
            fieldWhereList.push(
                {
                  archivesBasicTypeMethodCode: "=",
                  archivesBasicTypeCode: 'text',
                  key: 'integrityInfo',
                  value: this.inputForm.integrityInfo
                },
            )
          }
          this.searchRecord = {
            ...searchForm,
            fieldList: fieldList,
            fieldOrList,
            fieldWhereList,
            current: this.pageNo,
            size: this.pageSize,
            number: this.inputForm.helpNum,
            archivesBasicDataId: archivesBasicDataId,
          }
          this.$axios(this.api.collection.logoffList, this.searchRecord, 'post').then((res) => {
            if (res.status) {
              this.dataList = res.data.records
              this.total = parseInt(res.data.total)
              this.$nextTick(() => {
                this.$refs.multipleTable.doLayout()
              })
            } else {
              this.$message.error('查询失败');
            }
            this.loading = false
          })
        }
      })
    },

    // 获取元数据类型设置输入正则
    getNumType(item) {
      let regular = /[^\d]/g
      if (item.archivesBasicTypeCode == "double") {
        regular = /[^\d.]/g
      }
      this.$set(this.inputForm, item.code, this.inputForm[item.code].replace(regular, ''))
    },

    // 列表排序
    sortChange(column) {
      if (column.prop == 'createDate' || column.prop == 'updateDate') {
        this.searchForm.sortArchivesDataElasticsearch = [
          {
            archivesBasicTypeCode: 'date',
            isSys: 1,
            key: column.prop,
            sort: column.order == "descending" ? 'desc' : (column.order == "ascending" ? 'asc' : ''),
          }
        ]
      } else {
        let sortItem = this.config.filter(item => {
          return item.code == column.prop
        })
        this.searchForm.sortArchivesDataElasticsearch = [
          {
            archivesBasicTypeCode: sortItem[0].archivesBasicTypeCode,
            isSys: 0,
            key: 'archivesData.' + column.prop,
            sort: column.order == "descending" ? 'desc' : (column.order == "ascending" ? 'asc' : ''),
          }
        ]
      }
      this.getDataList('', 1)
    },

    //获取数据总条数
    getAllDataLength(num) {
      this.$axios(this.api.collection.selectCollectionDataCount, {
        entryState: num,
      }, 'get').then((res) => {
        this.allData = res.data
      })
    },

    //筛选树的数据
    filterTreeData() {
      console.log(this.filterText)
      this.$refs.tree.filter(this.filterText);
    },

    filterNode(value, data) {
      if (!value) return true;
      return data.archivesBasicName.indexOf(value) !== -1;
    },

    //重置
    resetting() {
      this.$refs.inputForm.resetFields();
      this.searchForm.whereList = []
      this.advancedList = []
      this.getDataList(1, 1, 1)
    },

    //批量导出
    batchExpor() {
      let ids = []
      if (this.dataListSelect.length == 0) {
        this.$message.warning('请至少选择一条数据')
        return
      }
      ids = this.dataListSelect.map(item => {
        if (item) {
          return {
            id: item.id,
            archivesBasicDataId: item.archivesBasicDataId
          }
        }
      })
      this.$refs.exportData.init(ids, this.config, this.archivesBasicDataId, '藏品数据', 1)
    },

    //1批量转总账 2 转总账
    ledge(num, row) {
      if (num == 1) {
        if (this.dataListSelect.length == 0) {
          this.$message.error('请勾选数据！')
        } else {
          let ids = this.dataListSelect.map(item => {
            return item.id
          })
          this.$refs.ledgeResult.init(ids.join(','), this.config)
        }
      } else {
        this.$refs.ledgeResult.init(row.id, this.config)
      }
      this.getDataList('', 1)
    },

    //num 1多个 2单个  type 2驳回 3通过
    through(num, row, type) {
      let setData = []
      let revdId
      if (num == 1) {
        if (this.dataListSelect.length == 0) {
          this.$message.warning('请至少选择一条数据')
          return
        }
        setData = this.dataListSelect
      } else {
        setData = [row]
      }
      if (this.typePage == 'cancellationReview') {
        revdId = 6
      } else {
        revdId = 5
      }
      let url = this.api.collection.batchExamine
      this.$refs.suggestions.init(num, setData, type, revdId, url, 'put')
    },

    //num 3审核
    detail(num, row, index) {
      let listSearch = {
        archivesBasicDataId: this.archivesBasicDataId,
        advancedList: this.advancedList,
        inputForm: this.inputForm,
        pageNo: this.pageNo,
        pageSize: this.pageSize
      }
      sessionStorage.setItem('listSearch', JSON.stringify(listSearch))
      this.$set(this.searchRecord, 'viewCurrent', (this.pageNo - 1) * this.pageSize + index + 1)
      sessionStorage.setItem('registrationSearch', JSON.stringify(this.searchRecord))
      this.$router.push({
        path: '/collection/business/collectionDet',
        query: {butType: num, id: row.id, archivesBasicDataId: row.archivesBasicDataId, typePage: this.typePage}
      })
    },

    //审核记录
    record(row) {
      let type = 6
      if (this.typePage == 'auxiliaryExamine') {
        type = 5
      }
      this.$refs.record.init(row.id, '', type)
    },

    // 展览每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.getDataList(1, 1)
    },
    // 展览当前页
    currentChangeHandle(val) {
      this.pageNo = val;
      this.getDataList('', '');
    },

    //表格勾选数据
    selectionChangeHandle(val, datae) {
      this.dataListSelect = val
    },
  },
}
</script>

<style lang="scss" scoped>
.itemCol {
  width: 30%;
  margin-right: 1%;
  border: 1px solid #C0C4CC;
  text-align: center;
  border-radius: 4px;
  font-size: 12px;
  padding: 25px 0;
}

.itemCol:nth-child(4n) {
  margin-right: 0;
}

.itemCol .num {
  font-weight: bold;
  font-size: 16px;
  padding: 8px 0;
}

.tabsCls {
  margin: 20px 0 0;
}

.noData {
  line-height: calc(100vh - 154px);
}

.table {
  .el-table__fixed-right,
  .el-table__fixed {
    height: auto !important;
    bottom: 17px !important;
  }
}

.leftHeighe {
  height: calc(100vh - 124px);
  overflow-y: auto;
}

.treeDateCls {
  overflow-y: auto !important;
  height: calc(100% - 80px);
}
</style>
